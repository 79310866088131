import React, { useState } from 'react';
import { Box, Container, Heading, Button, VStack, Text, Input, HStack, useToast, Flex, Spacer } from '@chakra-ui/react';
import { NextUIProvider } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Adjust this import path as needed
import NavigationBar from '../Components/NavigationBar';
import { useAuth } from '../AuthContext'; // Adjust this import path as needed

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const toast = useToast();
  const [searchEmail, setSearchEmail] = useState('');
  const [searchResult, setSearchResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/admin/login'); // Redirect to admin login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('applications')
        .select('*')
        .eq('email', searchEmail)
        .single();

      if (error) throw error;
      setSearchResult(data);
    } catch (error) {
      console.error('Error searching:', error);
      setSearchResult(null);
      toast({
        title: "Search Error",
        description: "No result found or an error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (column: 'accepted' | 'founding_membership') => {
    if (!searchResult) return;

    try {
      const { error } = await supabase
        .from('applications')
        .update({ [column]: true })
        .eq('id', searchResult.id);

      if (error) throw error;

      // Update local state
      setSearchResult({ ...searchResult, [column]: true });

      toast({
        title: "Update Successful",
        description: `User ${column === 'accepted' ? 'accepted' : 'given founding membership'}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating:', error);
      toast({
        title: "Update Error",
        description: "Failed to update user status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <NextUIProvider>
      <NavigationBar />
      <Box bg="#f7f4f1" minHeight="100vh" py={12}>
        <Container maxW="container.md">
          <VStack spacing={8} align="stretch">
          <Flex align="center">
              <Heading as="h1" size="2xl" className="libre-regular">
                Admin Dashboard
              </Heading>
              <Spacer />
              <Button
                onClick={handleLogout}
                colorScheme="red"
                size="sm"
                className="inter"
              >
                Logout
              </Button>
            </Flex>
            {session && (
              <Text textAlign="center" className="inter">
                Welcome, {session.user.email}
              </Text>
            )}
            
            <HStack>
              <Input 
                placeholder="Search by email" 
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                className="inter"
              />
              <Button 
                onClick={handleSearch} 
                colorScheme="blue" 
                isLoading={isLoading}
                className="inter"
              >
                Search
              </Button>
            </HStack>

            {searchResult ? (
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text className="inter">Email: {searchResult.email}</Text>
                <Text className="inter">Name: {searchResult.first_name} {searchResult.last_name}</Text>
                <HStack mt={4}>
                  <Button 
                    onClick={() => handleUpdate('accepted')} 
                    colorScheme="green" 
                    isDisabled={searchResult.accepted}
                    className="inter"
                  >
                    {searchResult.accepted ? 'Accepted' : 'Accept'}
                  </Button>
                  <Button 
                    onClick={() => handleUpdate('founding_membership')} 
                    colorScheme="purple" 
                    isDisabled={searchResult.founding_membership}
                    className="inter"
                  >
                    {searchResult.founding_membership ? 'Founding Member' : 'Give Founding Membership'}
                  </Button>
                </HStack>
              </Box>
            ) : (
              <Text textAlign="center" className="inter">No result</Text>
            )}
          </VStack>
        </Container>
      </Box>
    </NextUIProvider>
  );
};

export default AdminDashboard;