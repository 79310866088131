import { FC } from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import { InstagramLogo } from "../Components/InstagramLogo";
import { IshqLetterLogo } from "../Components/ishqLetterLogo";

const NavigationBar: FC = () => {
    return (
        <Navbar className="background">
      <NavbarContent>
      <NavbarBrand as={Link} href="/"> 
        <IshqLetterLogo />
      </NavbarBrand>
      
      </NavbarContent>
      <NavbarContent justify="center">
      
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Button className="nav-blog inter rounded-large" as={Link} href="/blog" variant="bordered">Blog</Button>
        </NavbarItem>
        <NavbarItem>
          <Button className="nav-apply inter rounded-large" as={Link}  href="https://instagram.com/findishq" variant="light">
            <InstagramLogo />
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
    
    )
}

export default NavigationBar;