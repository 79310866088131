import React, { useState } from 'react';
import { Box, Container, VStack, Heading, Button, Text, Input, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { NextUIProvider } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Adjust this import path as needed
import NavigationBar from '../Components/NavigationBar';
import { useAuth } from '../AuthContext'; // Adjust this import path as needed

const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState<'email' | 'otp'>('email');
  const navigate = useNavigate();
  const { session } = useAuth();

  React.useEffect(() => {
    if (session) {
      navigate('/admin/dashboard');
    }
  }, [session, navigate]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setError('');
  };

  const handleSendMagicLink = async () => {
    setIsLoading(true);
    setError('');

    const allowedEmails = ['nisha@findishq.com', 'sishir@findishq.com'];

    if (!allowedEmails.includes(email)) {
      setError('Access denied. Please use an authorized email address.');
      setIsLoading(false);
      return;
    }

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: email,
      });
      
      if (error) throw error;
      
      alert('Magic link sent to your email. Please check your inbox for the OTP.');
      setStep('otp');
    } catch (error: any) {
      console.error('Error sending magic link:', error);
      setError(`Failed to send magic link: ${error.message || 'Please try again.'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true);
    setError('');

    try {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'magiclink'
      });

      if (error) throw error;

      // The session will be automatically updated by the AuthProvider
      // and the user will be redirected to the admin dashboard
    } catch (error: any) {
      console.error('Error verifying OTP:', error);
      setError(`Failed to verify OTP: ${error.message || 'Please try again.'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NextUIProvider>
      <NavigationBar />
      <Box bg="#f7f4f1" minHeight="100vh" py={12}>
        <Container maxW="container.sm">
          <VStack spacing={8} align="stretch">
            <Heading as="h1" size="2xl" textAlign="center" className="libre-regular">
              Admin Login
            </Heading>
            {step === 'email' ? (
              <>
                <Text className="inter" textAlign="center">
                  Please enter your email to receive a magic link for admin access.
                </Text>
                <FormControl isInvalid={!!error}>
                  <FormLabel htmlFor="email" className="inter">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                    className="inter"
                  />
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
                <Button
                  onClick={handleSendMagicLink}
                  bg="#F4C430"
                  color="black"
                  _hover={{ bg: "#E5B62F" }}
                  size="lg"
                  width="full"
                  className="inter"
                  isLoading={isLoading}
                  loadingText="Sending..."
                >
                  Send Code to Email
                </Button>
              </>
            ) : (
              <>
                <Text className="inter" textAlign="center">
                  Please enter the 6-digit OTP sent to your email.
                </Text>
                <FormControl isInvalid={!!error}>
                  <FormLabel htmlFor="otp" className="inter">OTP</FormLabel>
                  <Input
                    id="otp"
                    type="text"
                    value={otp}
                    onChange={handleOtpChange}
                    placeholder="Enter 6-digit OTP"
                    className="inter"
                    maxLength={6}
                  />
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
                <Button
                  onClick={handleVerifyOtp}
                  bg="#F4C430"
                  color="black"
                  _hover={{ bg: "#E5B62F" }}
                  size="lg"
                  width="full"
                  className="inter"
                  isLoading={isLoading}
                  loadingText="Sending..."
                >
                  Verify OTP
                </Button>
              </>
            )}
          </VStack>
        </Container>
      </Box>
    </NextUIProvider>
  );
};

export default AdminLogin;